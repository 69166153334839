@import "styles/colours";

.Loader {
  &-ripple {
    display: inline-block;
    position: relative;
    width: 80px;
    height: 80px;

    & div {
      position: absolute;
      border: 4px solid $standout;
      opacity: 1;
      border-radius: 50%;
      animation: Loader-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
    }

  }

  &-ripple div:nth-child(2) {
    animation-delay: -0.5s;
  }
}

@keyframes Loader-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }
  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}
