@charset "utf-8";

// Import a Google Font
@import url('https://fonts.googleapis.com/css2?family=Lato:wght@300;400;700&display=swap');

// Update Bulma's global variables
$family-primary: "Lato", sans-serif;

@import "colours";
@import "mixins";

/*
* Import only what you need from Bulma
 */

/*
* Base
 */
//@import "node_modules/bulma/sass/base/_all.sass";

/*
* Components
 */
//@import "node_modules/bulma/sass/components/_all.sass";

/*
* Elements
 */
//@import "node_modules/bulma/sass/elements/_all.sass";

/*
* Form
 */
//@import "node_modules/bulma/sass/form/_all.sass";

/*
* Grid
 */
//@import "node_modules/bulma/sass/grid/_all.sass";

/*
* Helpers
 */
//@import "node_modules/bulma/sass/helpers/_all.sass";

/*
* Layout
 */
//@import "node_modules/bulma/sass/layout/_all.sass";

/*
* Utilities
 */
//@import "node_modules/bulma/sass/utilities/_all.sass";
@import "node_modules/bulma/sass/utilities/derived-variables";
$addColors: (
        "standout":($standout, $standout-invert),
        "secondary":($secondary, $secondary-invert),
        "error":($error, $error-invert)
);
$colors: map-merge($colors, $addColors);

/*
* This will import all of Bulma styles
 */
@import 'node_modules/bulma/bulma.sass';

/*
* Import Bulma-extensions
 */

@import "node_modules/bulma-tooltip/src/sass/index";
@import "node_modules/bulma-timeline/src/sass/index";
@import "node_modules/bulma-switch/src/sass/index";

// Import style sheets


// Misc
.is-multiline {
  flex-wrap: wrap;
}
.is-animatedButton, .is-pointer, .additionalButton {
  cursor:pointer;
}
.is-animatedButton:active  {
  transform: translateY(2px);
}

.moduleswitch, .additionalButton {
  display:none;
}
.moduleswitch.active, .additionalButton.active {
  display:block;
}

.structureBox {
  min-height: 90px;
}