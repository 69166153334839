.LogoContainer {
  &-pos-statement{
    max-width: 60%;
    height: auto;
  }

  &-gov-with{
    max-width: 90%;
    height: auto;
  }
}
