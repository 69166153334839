@import 'styles/colours';

.LoginBox {
  &-error-div {
    height: 18px;
  }

  &-inverted-btn {
    background-color: $secondary !important;

    &:hover {
      filter: brightness(97%)
    }
  }
}
