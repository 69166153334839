@import "../../node_modules/bulma/sass/utilities/functions";
// GovernWith colours
$azure: #223e4a;
$terracotta: #e88b6a;
$terracotta-invert: #fff;
$platinum: #E7E2DF;

// Extra Colours
$green : #D0F0D0; // #aee86b
$yellow : #F7F5C9; // #e8e26b
$red : #FFC6C6; // #e86b74

// Update Bulma's global variables
$family-serif: 'PT Serif', serif;
$grey-light: $platinum;
$primary: $azure;
$error: hsl(348, 100%, 61%);
$error-invert: findColorInvert($error);
$error-light: findLightColor($error);
$error-dark: findDarkColor($error);

$standout: $terracotta;
$standout-invert: $terracotta-invert;

$secondary: $platinum;
$secondary-invert: black;
